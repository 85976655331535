define("discourse/plugins/private-topics/discourse-private-topics/templates/connectors/topic-title/private-topics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span><li class="fa fa-lock private-topics-icon"></li>RESTRICTED ACCESS</span>
  
  */
  {
    "id": "sxVsEwJw",
    "block": "[[[10,1],[12],[10,\"li\"],[14,0,\"fa fa-lock private-topics-icon\"],[12],[13],[1,\"RESTRICTED ACCESS\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "discourse/plugins/private-topics/discourse-private-topics/templates/connectors/topic-title/private-topics.hbs",
    "isStrictMode": false
  });
});