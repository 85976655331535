define("discourse/plugins/private-topics/discourse-private-topics/templates/connectors/topic-footer-main-buttons-before-create/private-topics-button", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if siteSettings.private_topics_enabled}}
    {{#if topic.custom_fields.topic_restricted_access}}
      {{d-button
        icon="unlock"
        action="controlAccess"
        label='private_topics.btn_public_label.title'
        title="private_topics.btn_private_label.help"
      }}
    {{else}}
    {{d-button
      icon="lock"
      action="controlAccess"
      label='private_topics.btn_private_label.title'
      title="private_topics.btn_private_label.help"
    }}
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "Xvfmmha9",
    "block": "[[[41,[30,0,[\"siteSettings\",\"private_topics_enabled\"]],[[[41,[30,0,[\"topic\",\"custom_fields\",\"topic_restricted_access\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"icon\",\"action\",\"label\",\"title\"],[\"unlock\",\"controlAccess\",\"private_topics.btn_public_label.title\",\"private_topics.btn_private_label.help\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"icon\",\"action\",\"label\",\"title\"],[\"lock\",\"controlAccess\",\"private_topics.btn_private_label.title\",\"private_topics.btn_private_label.help\"]]]],[1,\"\\n\"]],[]]]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/private-topics/discourse-private-topics/templates/connectors/topic-footer-main-buttons-before-create/private-topics-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `topic` property path was used in the `discourse/plugins/private-topics/discourse-private-topics/templates/connectors/topic-footer-main-buttons-before-create/private-topics-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/private-topics/discourse-private-topics/templates/connectors/topic-footer-main-buttons-before-create/private-topics-button.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});