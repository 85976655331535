define("discourse/plugins/private-topics/discourse-private-topics/connectors/topic-footer-main-buttons-before-create/private-topics-button", ["exports", "discourse/lib/show-modal", "discourse/lib/ajax"], function (_exports, _showModal, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      return Discourse.User.currentProp('staff');
    },
    actions: {
      controlAccess() {
        const container = Discourse.__container__;
        let restrictedAccess = !this.topic.get('custom_fields.topic_restricted_access');
        var model = {
          topic: this.topic,
          restrictedAccess: restrictedAccess
        };
        let controller = container.lookup('controller:private-topics');
        controller.set('model', model);
        controller.send('controlAccess');
      }
    }
  };
});