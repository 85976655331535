define("discourse/plugins/private-topics/discourse-private-topics/controllers/private-topics", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api"], function (_exports, _ajax, _ajaxError, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { default as computed } from 'ember-addons/ember-computed-decorators';
  var _default = _exports.default = Ember.Controller.extend({
    actions: {
      controlAccess() {
        //? false : true;
        return (0, _ajax.ajax)("/privatetopic/control_access", {
          type: 'POST',
          data: {
            access_allowed: this.get('model.restrictedAccess'),
            topic_id: this.get('model.topic.id')
          }
        }).then(response => {
          if (Discourse.SiteSettings.private_topics_suppress) {
            let path = "/t/" + this.get('model.topic.id') + '/status';
            //Easiest way I could suppress from homepage for now.
            return (0, _ajax.ajax)(path, {
              type: 'PUT',
              data: {
                enabled: !response.success,
                status: 'visible'
              }
            }).then(() => {
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});